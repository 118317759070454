import { type RefObject, useCallback, useState } from 'react';

import { useIntersection } from './hooks/useIntersection';
import { sendGAEvent } from './utlis';

export const dataLayerClearEcommerceObject = () => {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
  }
};

export const dataLayerPush = (data) => {
  if (window && window.dataLayer) {
    window.dataLayer.push(data);
  }
};

export type TrackingData = {
  brand?: string;
  id: string;
  price: number;
  name: string;
  position?: number;
  module: string;
};

export const trackGAProductImpression = ({ id, brand, module, name, price }: TrackingData) => {
  dataLayerClearEcommerceObject();
  dataLayerPush({
    event: 'view_item_list',
    productPrice: price,
    productId: id,
    productName: name,
    productBrand: brand,
    module,
    item_list_id: module
  });
};

// TODO check event 'promotionClick' and callback with nextjs routing
export const trackGAProductClick = ({
  brand,
  id = '',
  module = '',
  name = 'no name specified',
  price
}: TrackingData) => {
  dataLayerClearEcommerceObject();
  const promoItemObj = {
    event: 'productClick',
    productPrice: price,
    productId: id,
    productName: name,
    productBrand: brand,
    module,
    item_list_id: module
  };
  sendGAEvent({ additionalTrackingParameters: promoItemObj });
};

export type TTrackProductAddToCartParameters = {
  id: string;
  buttonVariant?: 'pdp' | 'hit list view' | 'hit compact';
  brand?: string;
  category?: string;
  name?: string;
  price?: number;
  quantity?: number;
  variant?: string;
  sku: string;
};

export const trackGAAddToCart: (TTrackProductAddToCartParameters) => void = ({
  // brand = '',
  // name = '',
  // price = '',
  // quantity = 1,
  buttonVariant = ''
  // sku
}) => {
  const ecommerceObject = {
    event: 'addtocart',
    event_category: 'Ecommerce',
    event_action: `Add To Cart${buttonVariant ? ` - ${buttonVariant}` : ''}`
    // event_label: name,
    // event_value: quantity,
    // value: price,
    // // item variables
    // productBrand: brand,
    // productName: name,
    // productPrice: price,
    // productSku: sku
  };

  // dataLayerClearEcommerceObject();
  sendGAEvent({ additionalTrackingParameters: ecommerceObject });
};

export type TTrackPromotionImpressionParameters = {
  itemListId: string;
  itemListName: string;
};

export const trackGAPromotionImpression = ({ itemListId, itemListName }) => {
  dataLayerClearEcommerceObject();
  dataLayerPush({
    event: 'promotionImpression',
    ecommerce: {
      item_list_id: itemListId,
      item_list_name: itemListName
    }
  });
};

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#select_content
export type TTrackSelectContentParameters = {
  contentType: 'recipe' | 'article' | 'external' | 'other';
  contentId: string;
};

// TODO check event_callback for GA4
// TODO add element module
export const trackGASelectContent: (TTrackSelectContentParameters) => void = ({ contentType, contentId }) => {
  dataLayerPush({
    event: 'select_content',
    content_type: contentType,
    content_id: contentId
  });
};

// GA4

// see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#apply_promotions
// track GA4 internal promotion impression
export type TTrackGA4InternalPromotionImpressionParams = {
  promotion_id?: string;
  promotion_name?: string;
  creative_name?: string;
  creative_slot?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items?: Array<any>;
};
export const trackGA4InternalPromotionImpression: (TTrackGA4InternalPromotionImpressionParams) => void = ({
  promotion_id,
  promotion_name,
  creative_name,
  creative_slot,
  items
}) => {
  dataLayerPush({
    event: 'promotionImpression', // in gtag the event gets renamed to `view_promotion`
    ecommerce: {
      creative_name,
      creative_slot,
      promotion_id,
      promotion_name,
      items
    }
  });
};

// see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#apply_promotions
// track GA4 internal promotion impression
type TTrackGA4InternalPromotionClickParameters = {
  promotion_id?: string;
  promotion_name?: string;
  creative_name?: string;
  creative_slot?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items?: Array<any>;
};
export const trackGA4InternalPromotionClick: (params: TTrackGA4InternalPromotionClickParameters) => void = ({
  promotion_id,
  promotion_name,
  creative_name,
  creative_slot,
  items
}) => {
  dataLayerPush({
    event: 'select_promotion',
    ecommerce: {
      creative_name,
      creative_slot,
      promotion_id,
      promotion_name,
      items
    }
  });
};

export type useTrackModuleImpression = {
  ref: RefObject<HTMLElement>;
  label: string;
};

export const useTrackModuleImpression = ({ ref, label }: useTrackModuleImpression) => {
  const [viewed, setViewed] = useState<boolean>(false);

  const intersectionCallback = useCallback(() => {
    setViewed(true);
    sendGAEvent({
      category: 'Module Tracking',
      action: 'Module Impression',
      label
    });
  }, [label]);

  useIntersection(ref, intersectionCallback, {
    name: 'noThreshold',
    rootMargin: '0px',
    threshold: 0.3
  });

  return {
    viewed
  };
};
// sendGAEvent({ category: 'Module Tracking', action: 'Module Impression', label, ...additionalInformation });
